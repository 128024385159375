import Header from '../components/header';

import './css/App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default function PortfolioForm_Create() {
  const csrftoken = getCookie('csrftoken');
  const navigate = useNavigate();
  const [ErrorCreate, setErrorCreate] = useState(null);

  const [event, setEvent] = useState('');
  const [date, setDate] = useState('');
  const [hours, setHours] = useState('');
  const [role, setRole] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [description, setDescription] = useState('');
  const [contact, setContact] = useState('');

  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    client.get("/accounts")
      .then(function(res) {
        setCurrentUser(res.data.email);
        console.log(res.data.email);
      })
      .catch(function(error) {
        setCurrentUser(null);
        navigate('/login');
      });
  }, [navigate]);

  function validateCreate() {
    if (!event) return "Entry must have event";
    if (!date) return "Entry must have date";
    if (!hours) return "Entry must have hours";
    if (hours > 10) return "Hours cannot exceed 10";
    if (!role) return "Entry must have role";
    if (!organizer) return "Entry must have organizer";
    if (!contact) return "Entry must have contact";
    if (!description) return "Entry must have description";
    if (contact === currentUser) return "Contact cannot be your own email";
    return null;
  }

  function clickCreate(e) {
    e.preventDefault();

    const validationError = validateCreate();
    if (validationError) {
      setErrorCreate(validationError);
      return;
    }

    client.post(
      "/volunteer_hours_portfolio/create_entry",
      {
        event: event,
        date: date,
        hours: hours,
        role: role,
        organizer: organizer,
        contact: contact,
        description: description
      },
      {
        headers: {
          'X-CSRFToken': csrftoken
        }
      }
    ).then(function(res) {
      navigate('/hour-tracker');
    }).catch(function(ErrorCreate) {
      console.log(ErrorCreate.request);
      if (ErrorCreate.request && ErrorCreate.request.response) {
        let response;
        try {
          response = JSON.parse(ErrorCreate.request.response);
        } catch (e) {
          setErrorCreate("Error: Unable to parse error response");
          return;
        }
        let errorMessages = [];
        for (const field in response) {
          if (response[field] && response[field].length > 0) {
            errorMessages.push(`${field}: ${response[field].join(", ")}`);
          }
        }
        setErrorCreate("Error: " + errorMessages.join(" | "));
      } else if (ErrorCreate.request) {
        setErrorCreate("Error request: " + ErrorCreate.request);
      } else {
        setErrorCreate("Error: " + ErrorCreate.message);
      }
    });
  }

  const positiveHours = (event) => {
    const hours = parseInt(event.target.value);
    if (hours >= 0) setHours(hours);
  };

  return (
    <>
      <Header />
      <div className="center form-container" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px' }}>
        <h1 className="form-title">Record Entry</h1>

        <p>
          <span 
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/about-verification')}
          >
            Click here
          </span>{' '}
          to learn more about verification
        </p>


        <Form onSubmit={e => clickCreate(e)}>
          <Form.Group className="mb-3" controlId="formBasicEventName">
            <Form.Label>Event Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Event Name" value={event} onChange={e => setEvent(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" placeholder="Enter Date" value={date} onChange={e => setDate(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicHours">
            <Form.Label>Hours</Form.Label>
            <Form.Control type="number" placeholder="Enter Hours Worked" value={hours} onChange={positiveHours} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicRole">
            <Form.Label>Role</Form.Label>
            <Form.Control type="text" placeholder="Enter Role" value={role} onChange={e => setRole(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicOrganizer">
            <Form.Label>Organizer</Form.Label>
            <Form.Control type="text" placeholder="Enter Organizer" value={organizer} onChange={e => setOrganizer(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicContact">
            <Form.Label>Contact (Email Used for Verification)</Form.Label>
            <Form.Control type="text" placeholder="Enter Contact" value={contact} onChange={e => setContact(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="Enter Description" value={description} onChange={e => setDescription(e.target.value)} />
          </Form.Group>

          {ErrorCreate && <p style={{ color: 'red' }}>{ErrorCreate}</p>}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}
