import Header from '../components/header';
import './css/App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';

// AXIOS VARIABLES FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the URL only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

// Function to get the CSRF token from the cookie
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default function PortfolioForm_Update() {

  const csrftoken = getCookie('csrftoken');

  const { pk } = useParams();
  const navigate = useNavigate();
  const [ErrorUpdate, setErrorUpdate] = useState(null);

  const [event, setEvent] = useState('');
  const [date, setDate] = useState('');
  const [hours, setHours] = useState('');
  const [role, setRole] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [contact, setContact] = useState('');
  const [description, setDescription] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  // Check if user is authenticated, if not redirect to login
  useEffect(() => {
    client.get("/accounts")
      .then(() => setCurrentUser(true))
      .catch(() => {
        setCurrentUser(false);
        navigate('/login');
      });
  }, [navigate]);

  useEffect(() => {
    if (pk) {
      client.get(`/volunteer_hours_portfolio/update_entry/${pk}`)
        .then(res => {
          const data = res.data;
          setEvent(data.event);
          setDate(data.date);
          setHours(data.hours);
          setRole(data.role);
          setOrganizer(data.organizer);
          setContact(data.contact);
          setDescription(data.description);
        })
        .catch(err => {
          navigate('/hour-tracker');
        });
    }
  }, [pk, navigate]);

  function validateUpdate() {
    if (!event) return "Entry must have event";
    if (!date) return "Entry must have date";
    if (!hours) return "Entry must have hours";
    if (hours > 10) return "Hours cannot exceed 10";
    if (!role) return "Entry must have role";
    if (!organizer) return "Entry must have organizer";
    if (!contact) return "Entry must have contact";
    if (!description) return "Entry must have description";
    return null;
  }

  // Creating event
  function clickUpdate(e) {
    e.preventDefault();

    const validationError = validateUpdate();
    if (validationError) {
      setErrorUpdate(validationError);
      return;
    }

    const token = localStorage.getItem('authToken');
    const headers = {
      Authorization: `Token ${token}`
    };

    // Update the entry and set verification to false
    client.post(
      `/volunteer_hours_portfolio/update_entry/${pk}`,
      {
        event: event,
        date: date,
        hours: hours,
        role: role,
        organizer: organizer,
        contact: contact,
        description: description,
        verified: false  // Set verification to false
      },
      {
        headers: {
          'X-CSRFToken': csrftoken
        }
      }
    ).then(res => {
      // Resend verification email
      client.post(`/volunteer_hours_portfolio/resend-verification`, {
        id: pk,
      }, {
        headers: {
          'X-CSRFToken': csrftoken,
        }
      }).then(emailRes => {
        alert('Entry updated and verification email resent.');
        navigate('/hour-tracker');
      }).catch(emailErr => {
        console.error('Failed to resend verification email:', emailErr);
        alert('Entry updated, but failed to resend verification email.');
      });
    }).catch(err => {
      console.log(err.request);

      if (err.request && err.request.response) {
        let response;
        try {
          response = JSON.parse(err.request.response);
        } catch (e) {
          setErrorUpdate("Error: Unable to parse error response");
          return;
        }

        let errorMessages = [];

        for (const field in response) {
          if (response[field] && response[field].length > 0) {
            errorMessages.push(`${field}: ${response[field].join(", ")}`);
          }
        }

        if (errorMessages.length > 0) {
          setErrorUpdate("Error: " + errorMessages.join(" | "));
        } else {
          setErrorUpdate("Error: Unexpected response format");
        }
      } else if (ErrorUpdate.request) {
        setErrorUpdate("Error request: " + ErrorUpdate.request);
      } else {
        setErrorUpdate("Error: " + ErrorUpdate.message);
      }
    });
  }

  const positiveHours = (event) => {
    const hours = parseInt(event.target.value);
    if (hours >= 0) setHours(hours);
  };

  return (
    <>
      <Header />
      <div className="center form-container">
        <h1 className="form-title">Update Event</h1>

        <p style={{ color: 'red' }}>
      WARNING: Updating an entry will result in it being unverified. In order to learn more about how verification works on TimberwolveService, 
          <span 
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/about-verification')}
          >
            {' '}click here
          </span>
        </p>

        <p style={{ color: 'red' }}>You must fill in every input, even if you are not changing it, in order to update the event.</p>
        <Form onSubmit={clickUpdate}>
          <Form.Group className="mb-3" controlId="formBasicEventName">
            <Form.Label>Event Name</Form.Label>
            <Form.Control type="text" placeholder="Update Event" value={event} onChange={e => setEvent(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" placeholder="Update Date" value={date} onChange={e => setDate(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicHours">
            <Form.Label>Hours</Form.Label>
            <Form.Control type="number" placeholder="Update Hours Worked" value={hours} onChange={positiveHours} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicRole">
            <Form.Label>Role</Form.Label>
            <Form.Control type="text" placeholder="Update Role" value={role} onChange={e => setRole(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicOrganizer">
            <Form.Label>Organizer</Form.Label>
            <Form.Control type="text" placeholder="Update Organizer" value={organizer} onChange={e => setOrganizer(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicContact">
            <Form.Label>Contact (Email Used for Verification)</Form.Label>
            <Form.Control type="text" placeholder="Update Contact" value={contact} onChange={e => setContact(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="Update Description" value={description} onChange={e => setDescription(e.target.value)} />
          </Form.Group>

          {ErrorUpdate && <p style={{ color: 'red' }}>{ErrorUpdate}</p>}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}
